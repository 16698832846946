<template>
  <div ref="body">
    <custom-header active="design" :subActive="current"></custom-header>
    <div class="banner-container">
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/smart/banner.jpg" alt="" class="block" style="width: 100%; height: auto;">
    </div>
    <div class="content-container">
      <div class="section intro-box">
        <div class="logo">
          <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/smart/smart-logo.png" alt="" style="width: 100%; height: auto;">
        </div>
        <div class="intro">
          <!-- <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/smart/text.png" alt="" style="width: 100%; height: auto;"> -->
          <p>面对新一轮智能科技革命，时代赋予深圳打造全球智能家居产业高地的历史使命。从举办智能家居界技术驱动型大会，到展示深圳智能科技创新成果，深圳正在引领中国智能大潮。</p>
          <p>深圳市委、市政府做出重大战略部署，市工信局和市商务局统筹推进，集结全市智能家居领域13个重点产业先锋力量，并由深圳市家具行业协会智能家居专委会联动技术联盟、学术机构、智能协会共同打造的深圳国际智能家居博览会将于2025年正式启航.</p>
          <p>作为驱动中国智能家居产业技术革新与创新应用的会展引擎，智博会聚焦开源鸿蒙、开源欧拉、电力线载波、星闪技术、人工智能、AIoH等新技术新成果。全面呈现智能汽车、智能机器人、智能家居、全屋智能、智能照明、智能电器、智能厨房、智能卫浴、智能门窗、智能康养等智能终端新产品新应用。通过技术论坛、全场景展览，打造全球领先的智能技术成果发展与商业转化平台。预计将汇集全球400多家智能家居领域头部企业参展，吸引全国三十万专业买家来深观展。</p>
          <p>展望未来，相信在深圳市委、市政府的领导下，深圳国际智能家居博览会将发展成为全球最新智能技术和智能产品发布、产业化落地、商业化应用的首选平台，引领智能科技与智能空间革命，推动中国智能家居产业站在世界之巅。</p>
        </div>
      </div>
      <div class="section table-box">
        <h1 class="color text-bold">深圳国际智能家居博览会重磅官宣</h1>
        <div class="row">
          <div class="title color">展会名称</div>
          <div class="content">深圳国际智能家居博览会</div>
        </div>
        <div class="row">
          <div class="title color">展会时间</div>
          <div class="content">2025年3月16 - 19日</div>
        </div>
        <div class="row">
          <div class="title color">展会定位</div>
          <div class="content">全球领先的智能技术与智能产品的创新成果展示与商业转化平台</div>
        </div>
        <div class="row">
          <div class="title color">展会地点</div>
          <div class="content">
            <div>深圳国际会展中心（15号馆）</div>
            <div>深圳市宝安区福海街道展城路1号</div>
            <div class="color">同期举行深圳家居设计周暨深圳国际家具展</div>
            <div class="color">共享展会30万专业观众</div>
          </div>
        </div>
        <div class="row">
          <div class="title color">展会面积</div>
          <div class="content">20,000m²（预计）</div>
        </div>
        <div class="row">
          <div class="title color">展商数量</div>
          <div class="content">400家（预计）</div>
        </div>
        <div class="row">
          <div class="title color">展品类型</div>
          <div class="content">
            <div class="sub-row">
              <span class="sub-title">底层技术类：</span>
              <span class="sub-content">协议组织，芯片，模组，云平台技术，通讯运营AI视觉，AI语音。</span>
            </div>
            <div class="sub-row">
              <span class="sub-title">应用产品类：</span>
              <span class="sub-content">全屋智能，智能家电，智能控制，智能传感，智能遮阳，智能门锁，智能中控，智能影音娱乐，可视对讲，智能机器人，智能宠物用品，智能清洁，智能网络，环境控制，新能源汽车，智能五金，智能照明电工。</span>
            </div>
            <div class="sub-row">
              <span class="sub-title">解决方案类：</span>
              <span class="sub-content">智慧办公，智能酒店，智能社区，智慧安防，智能康养，智慧楼宇。</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="title color">观众类型</div>
          <div class="content1">
            <div class="flex-sub">
              <div>智能家居代理商/分销商/贸易商</div>
              <div>全屋智能及智能终端产品制造商  </div>
              <div>政府/住建/安居等大宗智能项目采购方</div>
              <div>智能控制/通讯/云平台/算力等技术机构</div>
              <div>智能工程服务商：商业空间、博物馆、高铁/道路、医院、学校等</div>
            </div>
            <div class="flex-sub">
              <div>智能家居设计师：AI设计、空间设计、产品设计等</div>
              <div>物业管理、集成服务方</div>
              <div>智能家居行业媒体、智能协会等</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="title title1 color">2025 支持单位信息</div>
          <div class="title title2 color">2025 <br/>支持单位信息</div>
          <div class="content">
            <div class="sub-row">
              <div class="flex-sub flex flex-wrap">
                <span class="sub-title">指导单位：</span>
                <div class="sub-content1">
                  <span>深圳市工业和信息化局</span>
                  <span>深圳市商务局</span>
                  <span>深圳市住房和建设局</span>
                  <span>深圳市建筑工务署</span>
                  <span>深圳市市场监督管理局</span>
                </div>
              </div>
              <div class="flex-sub flex flex-wrap">
                <span class="sub-title">学术支持：</span>
                <div class="sub-content1">
                  <span>南方科技大学创新学院</span>
                  <span>深圳市中城智慧城市研究院</span>
                  <span>深圳市电力线载波互联技术联盟</span>
                  <span>深圳市蓝海湾新能源与环境创新研究院</span>
                </div>
              </div>
            </div>
            <div class="sub-row">
              <div class="flex-sub flex flex-wrap">
                <span class="sub-title">主办单位：</span>
                <div class="sub-content1">
                  <span>深圳市家具行业协会智能家居专委会</span>
                </div>
              </div>
              <div class="flex-sub flex flex-wrap">
                <span class="sub-title">协办单位：</span>
                <div class="sub-content1">
                  <span>千家智客、沃菲传媒集团</span>
                </div>
              </div>
            </div>
            <div class="sub-row">
              <div class="flex-sub flex flex-wrap">
                <span class="sub-title">联合单位：</span>
                <div class="sub-content1">
                  <span>深圳市智能终端产业协会</span>
                  <span>深圳市人工智能产业协会</span>
                  <span>深圳市机器人协会</span>
                  <span>深圳市大新极客协会</span>
                  <span>居然之家</span>
                </div>
              </div>
              <div class="flex-sub flex flex-wrap">
                <span class="sub-title">承办单位：</span>
                <div class="sub-content1">
                  <span>深圳市德赛展览有限公司</span>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        
        <div class="row">
          <div class="title color">参展费用</div>
          <div class="content content3 flex">
            <div class="flex-sub">光地展位：人民币 1280元/㎡（最小面积40㎡）</div>
            <div class="flex-sub">标准展位：18,720元/9㎡（最小面积9㎡）</div>
          </div>
        </div>
        
        <div class="row">
          <div class="title color">参展联系</div>
          <div class="content flex">
            <span>深圳市德赛展览有限公司</span>
            <span>杨先生 13828730101</span>
          </div>
        </div>
        
        <div class="row table-bottom">
          <div class="color">
            <h3>欢迎更多优秀机构和品牌加入深圳国际智能家居博览会，共同助力中国智能科技革命与智能家居产业创新发展。</h3>
          </div>
          <div class="qr-code-box">
            <img src="../assets/img/exh-reg.png" alt="">
            参展报名
          </div>
        </div>
        
      </div>
      <div class="section">
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/smart/02.jpg" alt="" style="width: 100%; height: auto;">
      </div>
      <div class="section">
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/smart/03.jpg" alt="" style="width: 100%; height: auto;">
      </div>
      
      <div class="section">
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/smart/04.png" alt="" style="width: 100%; height: auto;">
      </div>
      <div class="section">
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/smart/05.png" alt="" style="width: 100%; height: auto;">
      </div>
      
      <div v-if="list.length > 0" class="news-container">
        <h2 class="color">新闻资讯</h2>
        
        <!-- PC端 -->
        <div class="news-list1 flex flex-wrap justify-between">
          <div v-for="(item, i) in list" :key="i" class="exhibitor flex">
            <div class="content-box" style="padding-left: 0;">
              <div class="title text-bold" @click="toNewsDetail(item)">{{item.newsTitle}}</div>
              <div class="intro">{{item.introduce}}</div>
            </div>
            <div class="cover-box" @click="toNewsDetail(item)">
              <img :src="item.newsCover" :alt="item.newsTitle" class="cover">
            </div>
          </div>
        </div>
        
        <!-- 移动端 -->
        <div class="news-list2">
          <div v-for="(item, i) in list" :key="i" class="exhibitor1 flex">
            <div class="cover-box" @click="toNewsDetail(item)">
              <img :src="item.newsCover" :alt="item.newsTitle" class="cover">
            </div>
            <div class="content-box" style="padding-right: 0;">
              <div class="title text-bold text-cut" @click="toNewsDetail(item)">{{item.newsTitle}}</div>
              <div class="intro">{{item.introduce}}</div>
            </div>
          </div>
        </div>
          
        <a href="/smart-news" class="link">查看更多</a>
      </div>
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
// import { Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getNewsList } from '@/api/index.js';

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    // [Icon.name]: Icon,
  },
  mixins: [commonMixin],
  metaInfo: {
    // title: 'My Example App', // set a title
    meta: [
      { name: 'keyWords', content: '智能家居，深圳智能家居，智能家居博览会，' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
    ],
  },
  data() {
    return {
      current: 'smart',
      list: [],
    }
  },
  created() {
    this.getNews();
  },
  mounted() {
    // 修改 meta 元素的内容
    var metaElement = document.querySelector('meta[name="keywords"]');
    metaElement.setAttribute('content', '智能家居，深圳智能家居，智能家居博览会，');
  },
  methods: {
    getNews() {
      let params = {
        keyword: '',
        pageNo: 1,
        pageSize: 10,
        type: 4, // 0-逛展小程序展会资讯, 1-展会资讯, 2-品牌资讯, 3-论坛资讯, 4-智能家居资讯
        // recommend: null, // 是否推荐, 1-显示在首页, 0-不推荐, 不传参-所有
        random: false,
      };
      
      getNewsList(params).then(res => {
        let {records = [], total = 0, pages = 1 } = res.resultData;
        this.list = records;
        this.total = total;
        this.pages = pages;
      })
    },
    toNewsDetail(row) {
      this.$router.push({
        path: '/newsDetail',
        query: {
          id: row.newsId,
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.banner-container {
  width: 100%;
}

.color {
  color: #393889;
}

// 移动端
@media screen and (max-width: 1023px) {
  .content-container {
    width: 375px;
    margin: 0 auto 20px;
    
    .section {
      width: 350px;
      margin: 30px auto 40px;
      
      .logo {
        width: 240px;
        margin: 0 auto 20px;
      }
      
      .intro {
        width: 350px;
        margin: 0 auto;
        
        p {
          text-indent: 2em;
          margin-bottom: 10px;
          font-size: 13px;
        }
      }
    }
    
    .table-box {
        
      h1 {
        font-size: 17px;
        text-align: center;
        margin-bottom: 10px;
      }
      
      .row {
        border-top: 1px solid #ddd;
        padding: 8px 0;
        
        .title,
        .title1 {
          width: 350px;
          font-size: 15px;
          font-family: 'SourceHanSansCN-Bold';
          margin-bottom: 5px;
        }
        .title2 {
          display: none;
        }
        
        .content1,
        .content {
          width: 350px;
          color: #333;
          font-size: 14px;
          line-height: 1.7;
          font-family: 'SourceHanSansCN-Medium';
          
          div, span {
            font-family: 'SourceHanSansCN-Medium';
          }
          .sub-row  {
            margin-bottom: 10px;
            justify-content: flex-start;
            
            .flex-sub {
              width: 350px;
              flex-wrap: nowrap;
              margin-top: 10px;
            }
            
            .sub-title {
              display: block;
              width: 100px;
              font-weight: bold;
            }
            .sub-content {
              display: block;
              width: 350px;
            }
            .sub-content1 {
              display: block;
              width: 350px;
              
              span {
                display: block;
              }
            }
          }
        }
        
        .content3 {
          display: block;
        }
        
        &.table-bottom {
          padding-top: 20px;
          
          h3 {
            font-size: 14px;
            width: 350px;
          }
          
          .qr-code-box {
            margin: 20px auto 0;
            width: 100px;
            font-size: 14px;
            text-align: center;
            font-family: 'SourceHanSansCN-Medium';
            
            img {
              display: block;
              width: 100%;
              height: auto;
              margin-bottom: 2px;
            }
          }
          
        }
      }
    }
    
    .news-container {
      width: 350px;
      margin: 30px auto 40px;
      
      h2 {
        font-family: 'SourceHanSansCN-Medium';
        font-size: 20px;
        text-align: center;
        margin-bottom: 24px;
      }
      
      .news-list1 {
        display: none;
      }
        
      .news-list2 {
        .exhibitor1 {
          width: 375px;
          margin-bottom: 24px;
          
          .cover-box {
            width: 130px;
            
            .cover {
              display: block;
              width: 100%;
              height: auto;
              border-radius: 4px;
            }
          }
          
          .content-box {
            width: 215px;
            padding: 0 10px;
            box-sizing: border-box;
            
            .title {
              font-size: 14px;
              margin-bottom: 5px;
            }
            
            .intro {
              font-size: 13px;
              line-height: 1.5em;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
          
        }
        
      }
    
      .link {
        display: block;
        background-color: #393889;
        color: #fff;
        font-size: 13px;
        text-align: center;
        width: 150px;
        height: 36px;
        line-height: 36px;
        margin: 40px auto 0;
        border-radius: 4px;
      }
    }
    
  }
}

@media screen and (min-width: 1024px) {
  .content-container {
    width: 280px;
    margin: 0 auto 20px;
    
    .intro-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    .section {
      width: 260px;
      margin: 30px auto;
      
      .logo {
        width: 80px;
      }
      
      .intro {
        width: 150px;
        
        p {
          text-indent: 2em;
          margin-bottom: 3px;
          font-size: 3.6px;
        }
      }
    }
    
    .table-box {
      
      h1 {
        font-size: 7.1px;
      }
      
      .row {
        display: flex;
        border-top: 0.6px solid #ddd;
        padding: 2px 0;
        
        .title1 {
          display: none;
        }
        .title2,
        .title {
          width: 38px;
          font-size: 4px;
          font-family: 'SourceHanSansCN-Bold';
        }
        
        .content1,
        .content {
          width: 240px;
          color: #333;
          font-size: 3.8px;
          line-height: 1.7;
          font-family: 'SourceHanSansCN-Medium';
          
          div, span {
            font-family: 'SourceHanSansCN-Medium';
          }
          .sub-row  {
            display: flex;
            margin-bottom: 2px;
            justify-content: flex-start;
            
            .sub-title {
              display: block;
              width: 25px;
            }
            .sub-content {
              display: block;
              width: 180px;
            }
            .sub-content1 {
              display: block;
              width: 65px;
              
              span {
                display: block;
              }
            }
          }
        }
        
        .content1 {
          display: flex;
        }
        
        &.table-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          
          h3 {
            font-size: 4px;
            width: 103px;
          }
          
          .qr-code-box {
            margin-top: 5px;
            width: 30px;
            font-size: 3.6px;
            text-align: center;
            font-family: 'SourceHanSansCN-Medium';
            
            img {
              display: block;
              width: 100%;
              height: auto;
              margin-bottom: 2px;
            }
          }
          
        }
        
      }
    }
  
    .news-container {
      width: 280px;
      margin: 30px auto;
      
      h2 {
        font-family: 'SourceHanSansCN-Medium';
        font-size: 8px;
        text-align: center;
        margin-bottom: 12px;
      }
      
      .news-list2 {
        display: none;
      }
      
      .news-list1 {
        
        .exhibitor {
          width: 130px;
          margin-bottom: 10px;
          padding: 0 5px;
          
          .cover-box {
            width: 60px;
            cursor: pointer;
            
            .cover {
              display: block;
              width: 100%;
              border-radius: 1px;
            }
          }
          
          .content-box {
            width: 60px;
            padding: 0 4px;
            box-sizing: border-box;
            
            .title {
              font-size: 3.6px;
              margin-bottom: 4px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-weight: bold;
              cursor: pointer;
            }
            
            .intro {
              width: 100%;
              font-size: 3px;
              line-height: 4.5px;
              text-align: justify;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
            }
          }
          
        }
        
      }
      
      .link {
        display: block;
        background-color: #393889;
        color: #fff;
        font-size: 3.6px;
        text-align: center;
        width: 40px;
        height: 10px;
        line-height: 10px;
        margin: 10px auto 0;
        border-radius: 1px;
      }

    }
    
  }
}
</style>